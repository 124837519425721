import { isArray } from 'lodash';

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (!file.rawFile) {
      return resolve({ src: file.src });
    }

    reader.readAsDataURL(file.rawFile);

    reader.onload = () => {
      resolve({ content: reader.result });
    };

    reader.onerror = reject;
    return reject;
  });

const addUploadFeature = (requestHandler) => async (type, resource, params) => {
  if ((type === 'UPDATE' || type === 'CREATE') && resource === 'inventory') {
    // send only new doorCodes or empty array
    if (
      (params.data.doorCodes &&
        params.data.doorCodes.length > 0 &&
        !params.data.doorCodesFile) ||
      params.data.doorCodes === null
    ) {
      delete params.data.doorCodes;
    }

    delete params.data.doorCodesFile;

    if (params.data && params.data.images) {
      const images = [];
      if (!isArray(params.data.images)) {
        params.data.images = [params.data.images];
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const photo of params.data.images) {
        if (photo) {
          images.push({
            // eslint-disable-next-line no-await-in-loop
            ...(await convertFileToBase64(photo)),
            name: photo.name,
          });
        }
      }
      params = {
        ...params,
        data: {
          ...params.data,
          images,
        },
      };
    }
    if (params.data && params.data.mapImage) {
      const mapImage = await convertFileToBase64(params.data.mapImage);
      params = {
        ...params,
        data: {
          ...params.data,
          mapImage: { ...mapImage, name: mapImage.name },
        },
      };
    }
  }
  if (
    (type === 'UPDATE' || type === 'CREATE') &&
    (resource === 'blog_posts' || resource === 'products')
  ) {
    if (params.data && params.data.image) {
      const image = await convertFileToBase64(params.data.image);
      params = {
        ...params,
        data: {
          ...params.data,
          image,
        },
      };
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadFeature;
